
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, FormSection, FormSubmission } from '@/models';
import { BaseButton, ButtonLink, SmartFormComplete, SmartFormSection } from '@/lib/components';
import LensOrdering from '@/custom/menicon/views/encounter/LensOrdering.vue';
import LensOrderingConfirmation from '@/custom/menicon/views/encounter/LensOrderingConfirmation.vue';
import { countErrors, validate } from '@/helpers/validators.helper';
import { SavingStatus } from '@/lib/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import { Laterality } from '@/custom/menicon/models';
import SurveyResultCharts from '@/custom/menicon/SurveyResultCharts.vue';
import { ILensOrderingAnswer } from '@/custom/menicon';
import { EncounterData } from '@/helpers/encounter.helper';
import EndPrescription from '@/custom/menicon/components/EndPrescription.vue';
import InstructionModal from '@/custom/menicon/views/encounter/InstructionModal.vue';
import InitialVARefraction from '@/custom/menicon/views/encounter/initial-measurement/InitialMeasurementVARefraction.vue';
import MyopiaPrognosisPage from '@/custom/menicon/views/encounter/MyopiaPrognosisPage.vue';
import DistanceVA from '@/custom/menicon/views/encounter/DistanceVA.vue';
import SlitLampWithoutLenses from '@/custom/menicon/views/encounter/SlitLampWithoutLenses.vue';

@Options({
  components: {
    BaseButton,
    ButtonLink,
    DistanceVA,
    EndPrescription,
    InitialVARefraction,
    InstructionModal,
    LensOrdering,
    LensOrderingConfirmation,
    MyopiaPrognosisPage,
    SmartFormComplete,
    SmartFormSection,
    SurveyResultCharts,
    WorkflowLayout,
    SlitLampWithoutLenses
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    journeyId: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    patient: {
      type: Object,
      required: true
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    endPrescriptionValue: {
      type: Object,
      default: () => ({
        reasons: [],
        details: ''
      })
    },
    journeyType: {
      type: String,
      required: true
    },
    currentPrescriptions: {
      type: Object,
      default: null
    },
    encounterData: {
      type: Object,
      default: null
    },
    encounter: {
      type: Object,
      default: null
    },
    formSubmission: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    },
    laterality: {
      type: String,
      default: null
    },
    lateralities: {
      type: Array,
      default: () => [Laterality.right, Laterality.left]
    },
    directOrder: {
      type: Boolean,
      default: true
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  laterality!: Laterality | null;
  errors!: { [key: string]: string[] };
  initialEncounter!: EncounterData;
  encounterData!: EncounterData;
  encounter!: Encounter;
  directOrder!: boolean;
  rules!: { [key: string]: any };
  endPrescription = false;

  formSubmission!: FormSubmission | null;
  prescriptionData: ILensOrderingAnswer | null = null;
  slitLampWithContactLensesSectionId = '5e13296f-1367-4dbf-8f46-5044e43878d9';

  get smartFormCompleteProps() {
    if (this.endPrescription) {
      return {
        heading: this.$t('custom.menicon.encounter.prescription-ended-confirmation.heading') as string,
        body: this.$t('custom.menicon.encounter.prescription-ended-confirmation.body') as string
      };
    }

    if (this.directOrder) {
      return {
        heading: this.$t('custom.menicon.prescriptions.order-placed-heading') as string,
        body: this.$t('custom.menicon.prescriptions.order-placed-body') as string
      };
    }

    return {
      heading: this.$t('custom.menicon.prescriptions.consult-complete-heading') as string,
      body: this.$t('custom.menicon.prescriptions.consult-complete-body') as string
    };
  }

  get isVaSatisfactory(): boolean {
    return !!(this.encounter?.l_is_va_satisfactory && this.encounter?.r_is_va_satisfactory);
  }

  get lensOrderingData() {
    /**
     * If the question “Is the VA satisfactory” (step 2) was answered as NO for EITHER eye,
     * the values entered on the Rx and VA screen of that workflow (step 5) should be the ones sent to MLS for recalculating the new lens
     * (not the initial measurement values)
     */
    if (this.isVaSatisfactory) {
      return this.initialEncounter?.lensOrdering;
    }
    return {
      ...this.initialEncounter?.lensOrdering,
      rSphere: this.encounterData.lensOrdering.rSphere,
      rCylinder: this.encounterData.lensOrdering.rCylinder,
      rAddition: this.encounterData.lensOrdering.rAddition,
      lSphere: this.encounterData.lensOrdering.lSphere,
      lCylinder: this.encounterData.lensOrdering.lCylinder,
      lAddition: this.encounterData.lensOrdering.lAddition
    };
  }

  get showVirtualDoctor() {
    return isFeatureFlagEnabled(FEATURES.VIRTUAL_DOCTOR);
  }

  get isInstructionModalStep(): boolean {
    return this.showVirtualDoctor ? this.step === 2 : this.step === 1;
  }

  get isPrognosisEnabled() {
    return isFeatureFlagEnabled(FEATURES.MYOPIA_PROGNOSIS);
  }

  get steps(): string[] {
    return [
      ...(this.showVirtualDoctor ? [this.$t('custom.menicon.virtual-doctor.name') as string] : []),
      this.$t('custom.menicon.encounter.bloom-day-6-or-12-assessment.va-and-lens-assessment.name') as string,
      '', // INSTRUCTION MODAL
      this.$t('custom.menicon.encounter.slit-lamp-without-lenses.name') as string,
      this.$t('custom.menicon.encounter.bloom-day-6-or-12-assessment.rx-and-va.name') as string,
      ...(this.isPrognosisEnabled ? [this.$t('custom.menicon.encounter.myopia-prognosis.name') as string] : []),
      this.$t('custom.menicon.encounter.lens-ordering.name') as string,
      ...(this.endPrescription
        ? [this.$t('custom.menicon.lens-ordering.cancel.title') as string]
        : [this.$t('custom.menicon.encounter.lens-ordering-confirmation.name') as string])
    ];
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get slitLampWithContactLensesSection(): FormSection | undefined {
    return this.formSubmission?.form?.schema?.sections.find(
      (section: FormSection) => section.id === this.slitLampWithContactLensesSectionId
    );
  }

  get currentSectionId(): string | null {
    if (
      this.step ===
      this.getStep(
        this.$t('custom.menicon.encounter.bloom-day-6-or-12-assessment.va-and-lens-assessment.name') as string
      )
    ) {
      return this.slitLampWithContactLensesSectionId;
    }
    return null;
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  created() {
    this.$watch('encounter', () => {
      if (this.errorCount) {
        this.validateCurrentStep();
      }
    });
  }

  onEndPrescriptionClick() {
    this.endPrescription = true;
    this.next();
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next', this.currentSectionId);
    }
  }

  complete() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('complete', {
        sectionId: this.currentSectionId,
        order: this.endPrescription ? null : this.prescriptionData
      });
    }
  }

  validateCurrentStep() {
    let errors = {};
    if (
      this.step ===
        this.getStep(this.$t('custom.menicon.encounter.bloom-day-6-or-12-assessment.rx-and-va.name') as string) &&
      this.rules?.vaRefraction
    ) {
      errors = validate(this.rules?.vaRefraction, this.encounter);
    }

    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.slit-lamp-without-lenses.name') as string) &&
      this.rules?.slitLamp
    ) {
      errors = validate(this.rules.slitLamp, this.encounter);
    }

    if (
      this.step ===
        this.getStep(
          this.$t('custom.menicon.encounter.bloom-day-6-or-12-assessment.va-and-lens-assessment.name') as string
        ) &&
      this.rules?.vaAndLensAssessment
    ) {
      errors = validate(this.rules?.vaAndLensAssessment, this.encounter);
    }

    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.lens-ordering.name') as string) &&
      this.prescriptionData
    ) {
      errors = this.prescriptionData.errors;
    }
    this.$emit('setErrors', errors);
  }

  updatePrescriptionData(data: ILensOrderingAnswer) {
    this.prescriptionData = data;
    if (this.errorCount) {
      this.validateCurrentStep();
    }
  }

  back() {
    this.endPrescription = false;
    this.$emit('back');
  }

  getStep(name: string): number {
    return this.steps.findIndex((step) => step === name);
  }
}
